import { PMultiSelect, PMultiSelectOption } from "@porsche-design-system/components-react/ssr";
import type {
    PMultiSelectProps,
    PMultiSelectOptionProps,
    MultiSelectUpdateEventDetail,
} from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type MultiSelectProps = PMultiSelectProps & BoxProps;
type MultiSelectOptionProps = PMultiSelectOptionProps & BoxProps;

const MultiSelect = chakra<typeof PMultiSelect, MultiSelectProps>(PMultiSelect);

const MultiSelectOption = chakra<typeof PMultiSelectOption, MultiSelectOptionProps>(
    PMultiSelectOption
);

export { MultiSelect, MultiSelectOption };
export type { MultiSelectProps, MultiSelectUpdateEventDetail };
