import { PRadioButtonWrapper } from "@porsche-design-system/components-react/ssr";
import type { PRadioButtonWrapperProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type RadioButtonWrapperProps = PRadioButtonWrapperProps & BoxProps;

const RadioButtonWrapper = chakra<typeof PRadioButtonWrapper, RadioButtonWrapperProps>(
    PRadioButtonWrapper
);

export { RadioButtonWrapper };
export type { RadioButtonWrapperProps };
