import { PPinCode } from "@porsche-design-system/components-react/ssr";
import type {
    PPinCodeProps,
    PinCodeUpdateEventDetail,
} from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type PinCodeProps = PPinCodeProps & BoxProps;

const PinCode = chakra<typeof PPinCode, PinCodeProps>(PPinCode);

export { PinCode };
export type { PinCodeProps, PinCodeUpdateEventDetail };
