import { PInlineNotification } from "@porsche-design-system/components-react/ssr";
import type { PInlineNotificationProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type InlineNotificationProps = PInlineNotificationProps & BoxProps;

const InlineNotification = chakra<typeof PInlineNotification, InlineNotificationProps>(
    PInlineNotification
);

export { InlineNotification };
export type { InlineNotificationProps };
