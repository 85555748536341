import {
    PFlyoutMultilevel,
    PFlyoutMultilevelItem,
} from "@porsche-design-system/components-react/ssr";
import type {
    PFlyoutMultilevelProps,
    PFlyoutMultilevelItemProps,
    FlyoutMultilevelUpdateEventDetail,
    FlyoutMotionVisibleEndEventDetail,
    FlyoutMotionHiddenEndEventDetail,
} from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type FlyoutMultilevelProps = PFlyoutMultilevelProps & BoxProps;
type FlyoutMultilevelItemProps = PFlyoutMultilevelItemProps & BoxProps;

const FlyoutMultilevel = chakra<typeof PFlyoutMultilevel, FlyoutMultilevelProps>(PFlyoutMultilevel);
const FlyoutMultilevelItem = chakra<typeof PFlyoutMultilevelItem, FlyoutMultilevelItemProps>(
    PFlyoutMultilevelItem
);

export { FlyoutMultilevel, FlyoutMultilevelItem };
export type {
    FlyoutMultilevelProps,
    FlyoutMultilevelItemProps,
    FlyoutMultilevelUpdateEventDetail,
    FlyoutMotionVisibleEndEventDetail,
    FlyoutMotionHiddenEndEventDetail,
};
