import { PButtonTile } from "@porsche-design-system/components-react/ssr";
import type { PButtonTileProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type ButtonTileProps = PButtonTileProps & BoxProps;

const ButtonTile = chakra<typeof PButtonTile, PButtonTileProps>(PButtonTile);

export { ButtonTile };
export type { ButtonTileProps };
