import { PLinkTileModelSignature } from "@porsche-design-system/components-react/ssr";
import type { PLinkTileModelSignatureProps } from "@porsche-design-system/components-react/ssr";
import { chakra, forwardRef } from "@chakra-ui/react";
import { NextLink } from "../../nextjs/link/NextLink";
import type { NextLinkProps } from "../../nextjs/link/NextLink";

type LinkTileModelSignatureProps = Omit<NextLinkProps, "as"> &
    Omit<PLinkTileModelSignatureProps, "href">;

const ChakraPLinkTileModelSignature = chakra<
    typeof PLinkTileModelSignature,
    LinkTileModelSignatureProps
>(PLinkTileModelSignature);

const LinkTileModelSignature = forwardRef<LinkTileModelSignatureProps, "a">((props, ref) => {
    if (props.target === "_blank") {
        return <ChakraPLinkTileModelSignature {...props} ref={ref} />;
    }

    return (
        <NextLink href={props.href} passHref legacyBehavior>
            <ChakraPLinkTileModelSignature {...props} ref={ref} />
        </NextLink>
    );
});

export { LinkTileModelSignature };
export type { LinkTileModelSignatureProps };
