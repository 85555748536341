import {
    PSegmentedControl,
    PSegmentedControlItem,
} from "@porsche-design-system/components-react/ssr";
import type {
    PSegmentedControlProps,
    PSegmentedControlItemProps,
    SegmentedControlUpdateEventDetail,
} from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type SegmentedControlProps = PSegmentedControlProps & BoxProps;
type SegmentedControlItemProps = PSegmentedControlItemProps & BoxProps;

const SegmentedControl = chakra<typeof PSegmentedControl, SegmentedControlProps>(PSegmentedControl);

const SegmentedControlItem = chakra<typeof PSegmentedControlItem, SegmentedControlItemProps>(
    PSegmentedControlItem
);

export { SegmentedControl, SegmentedControlItem };
export type { SegmentedControlProps, SegmentedControlItemProps, SegmentedControlUpdateEventDetail };
