import type { TextProps } from "../../components/chakra-ui/text";
import { Text } from "../../components/chakra-ui/text";
import { useCldVideoPlayer } from "./cld-video-context";
import { useSecondsAsTime } from "./hooks";

const CldVideoTotalTime = (props: Omit<TextProps, "children">) => {
    const {
        state: { duration },
    } = useCldVideoPlayer();

    const totalTime = useSecondsAsTime(duration);

    return (
        <Text as="span" {...props}>
            {totalTime}
        </Text>
    );
};
CldVideoTotalTime.displayName = "CldVideoTotalTime";

export { CldVideoTotalTime };
