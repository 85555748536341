import type { TextProps } from "../../components/chakra-ui/text";
import { Text } from "../../components/chakra-ui/text";
import { useCldVideoPlayer } from "./cld-video-context";
import { useSecondsAsTime } from "./hooks";

const CldVideoTimeRemaining = (props: Omit<TextProps, "children">) => {
    const {
        state: { duration, played },
    } = useCldVideoPlayer();

    const timeRemaining = useSecondsAsTime(duration - played.playedSeconds);

    return (
        <Text as="span" {...props}>
            {timeRemaining}
        </Text>
    );
};
CldVideoTimeRemaining.displayName = "CldVideoTimeRemaining";

export { CldVideoTimeRemaining };
