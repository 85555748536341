import { PScroller } from "@porsche-design-system/components-react/ssr";
import type {
    PScrollerProps,
    ScrollerScrollToPosition,
} from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type ScrollerProps = PScrollerProps & BoxProps;

const Scroller = chakra<typeof PScroller, ScrollerProps>(PScroller);

export { Scroller };
export type { ScrollerProps, ScrollerScrollToPosition };
