import { PTabsBar } from "@porsche-design-system/components-react/ssr";
import type {
    PTabsBarProps,
    TabsUpdateEventDetail,
} from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type TabsBarProps = PTabsBarProps & BoxProps;

const TabsBar = chakra<typeof PTabsBar, TabsBarProps>(PTabsBar);

export { TabsBar };
export type { TabsBarProps, TabsUpdateEventDetail };
