import { PTextFieldWrapper } from "@porsche-design-system/components-react/ssr";
import type { PTextFieldWrapperProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type TextFieldWrapperProps = PTextFieldWrapperProps & BoxProps;

const TextFieldWrapper = chakra<typeof PTextFieldWrapper, TextFieldWrapperProps>(PTextFieldWrapper);

export { TextFieldWrapper };
export type { TextFieldWrapperProps };
