import { useEffect, useState } from "react";

import { Flex } from "../../chakra-ui/flex";
import type { FlexProps } from "../../chakra-ui/flex";

import { Digit } from "./Digit";
import { DigitBox } from "./DigitBox";

type AnimatedDigitCardProps = FlexProps & {
    currentDigits: number[];
    previousDigits: number[];
};

const AnimatedDigitCard = ({ currentDigits, previousDigits }: AnimatedDigitCardProps) => {
    const [displayUnit, setDisplayUnit] = useState(previousDigits);

    useEffect(() => {
        setDisplayUnit(currentDigits);
    }, [currentDigits]);

    return (
        <Flex className="animatedDigitCards" h="full" gap={{ base: "2px", md: 1 }}>
            {displayUnit.map((digit, key) => {
                const shouldAnimate = previousDigits[key] !== currentDigits[key];

                return (
                    <DigitBox key={`animatedFlipCard-${digit}-${key}`}>
                        <Digit
                            initial={{ opacity: shouldAnimate ? 0 : 1 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.6, ease: "easeInOut" }}
                        >
                            {digit}
                        </Digit>
                    </DigitBox>
                );
            })}
        </Flex>
    );
};

export { AnimatedDigitCard };
