import { PPopover } from "@porsche-design-system/components-react/ssr";
import type { PPopoverProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type PopoverProps = PPopoverProps & BoxProps;

const Popover = chakra<typeof PPopover, PopoverProps>(PPopover);

export { Popover };
export type { PopoverProps };
