import type { PropsWithChildren } from "react";
import type { FlexProps } from "../../chakra-ui/flex";
import { Flex } from "../../chakra-ui/flex";

type DigitBoxProps = PropsWithChildren & FlexProps;

const DigitBox = ({ children, ...props }: DigitBoxProps) => {
    return (
        <Flex
            borderRadius="medium"
            bgColor="blackOpaque"
            justifyContent="center"
            alignItems="center"
            w={{ base: 7, md: 10 }}
            height={{
                base: 9,
                md: 14,
            }}
            backdropFilter="blur(32px)"
            {...props}
        >
            {children}
        </Flex>
    );
};

export { DigitBox };
