import { useCallback, useEffect, useState } from "react";
import screenfull from "screenfull";
import { useCldVideoPlayer } from "./cld-video-context";
import { Button } from "../../components/porsche-design-system/button";
import type { ButtonProps } from "../../components/porsche-design-system/button";
import { ButtonPure } from "../../components/porsche-design-system/button-pure";

type CldVideoToggleFullscreenProps = Omit<ButtonProps, "aria-label"> & {
    mobile?: boolean;
};

const CldVideoToggleFullscreen = (props: CldVideoToggleFullscreenProps) => {
    const { mobile, ...rest } = props;

    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        setShouldRender(screenfull.isEnabled);
    }, []);

    const {
        state: { playerRef, isShowingFullscreen },
        dispatch,
    } = useCldVideoPlayer();

    const handleClick = useCallback(async () => {
        if (!playerRef?.current || !screenfull.isEnabled) {
            return;
        }

        const videoElement = playerRef.current.getInternalPlayer();

        // getInternalPlayer can also return a reference to i.e. the YouTube player instance on which we cant call the browser requestFullscreen method.
        if (!(videoElement instanceof HTMLVideoElement)) {
            return;
        }

        await screenfull.request(videoElement, { navigationUI: "hide" });
    }, [playerRef]);

    useEffect(() => {
        const handleFullScreenChange = () => {
            if (screenfull.isFullscreen) {
                dispatch({ type: "SHOW_FULLSCREEN" });
            } else {
                dispatch({ type: "HIDE_FULLSCREEN" });
            }
        };

        screenfull.on("change", handleFullScreenChange);

        return () => {
            screenfull.off("change", handleFullScreenChange);
        };
    }, [dispatch]);

    if (!shouldRender) {
        return null;
    }

    if (mobile) {
        return (
            <ButtonPure
                onClick={handleClick}
                theme="dark"
                {...rest}
                hideLabel={true}
                icon="zoom-out"
                aria-label={isShowingFullscreen ? "exit fullscreen" : "enter fullscreen"}
                border="2px solid var(--colors-allWhite)"
                borderRadius="var(--radii-small)"
                size="x-large"
            />
        );
    }

    return (
        <Button
            onClick={handleClick}
            theme="dark"
            variant="secondary"
            {...rest}
            hideLabel={true}
            icon="zoom-out"
            aria-label={isShowingFullscreen ? "exit fullscreen" : "enter fullscreen"}
        />
    );
};
CldVideoToggleFullscreen.displayName = "CldVideoToggleFullscreen";

export { CldVideoToggleFullscreen };
