import { PTextareaWrapper } from "@porsche-design-system/components-react/ssr";
import type { PTextareaWrapperProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type TextareaWrapperProps = PTextareaWrapperProps & BoxProps;

const TextareaWrapper = chakra<typeof PTextareaWrapper, TextareaWrapperProps>(PTextareaWrapper);

export { TextareaWrapper };
export type { TextareaWrapperProps };
