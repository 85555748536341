import { useCallback, useEffect, useState } from "react";
import type { SliderProps } from "../../components/chakra-ui/slider";
import {
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
} from "../../components/chakra-ui/slider";
import { useCldVideoPlayer } from "./cld-video-context";

const CldVideoScrubber = (props: SliderProps) => {
    const {
        state: { played, playerRef },
        dispatch,
    } = useCldVideoPlayer();

    const [sliderValue, setSliderValue] = useState(played.played);

    useEffect(() => {
        setSliderValue(played.played);
    }, [played.played]);

    const [randomUUID, setRandomUUID] = useState<string>();

    useEffect(() => {
        if (typeof window !== "undefined" && !randomUUID) {
            setRandomUUID(crypto.randomUUID());
        }
        // we want to assign this only once on initial CSR.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSeekPointerDown = useCallback(() => {
        dispatch({ type: "SET_IS_SEEKING", payload: true });
    }, [dispatch]);

    const handleSeekPointerUp = useCallback(() => {
        dispatch({ type: "SET_IS_SEEKING", payload: false });
    }, [dispatch]);

    const handleSeekChange = useCallback(
        (value: number) => {
            if (!playerRef?.current) {
                return;
            }

            playerRef.current.seekTo(value);
            setSliderValue(value);
        },
        [playerRef]
    );

    return (
        <Slider
            min={0}
            max={0.999999}
            step={0.000001}
            {...props}
            value={sliderValue}
            onChangeStart={handleSeekPointerDown}
            onChangeEnd={handleSeekPointerUp}
            onChange={handleSeekChange}
            focusThumbOnChange={false}
            name={randomUUID}
        >
            <SliderTrack>
                <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
        </Slider>
    );
};
CldVideoScrubber.displayName = "CldVideoScrubber";

export { CldVideoScrubber };
