import { PLinkTile } from "@porsche-design-system/components-react/ssr";
import type { PLinkTileProps } from "@porsche-design-system/components-react/ssr";
import { chakra, forwardRef } from "@chakra-ui/react";
import { NextLink } from "../../nextjs/link/NextLink";
import type { NextLinkProps } from "../../nextjs/link/NextLink";

type LinkTileProps = Omit<NextLinkProps, "as"> & Omit<PLinkTileProps, "href">;

const ChakraPLinkTile = chakra<typeof PLinkTile, LinkTileProps>(PLinkTile);

const LinkTile = forwardRef<LinkTileProps, "a">((props, ref) => {
    if (props.target === "_blank") {
        return <ChakraPLinkTile {...props} ref={ref} />;
    }

    return (
        <NextLink href={props.href} passHref legacyBehavior>
            <ChakraPLinkTile {...props} ref={ref} />
        </NextLink>
    );
});

export { LinkTile };
export type { LinkTileProps };
