import { useState } from "react";
import { useInterval } from "@chakra-ui/react";
import { differenceInYears, differenceInMonths, differenceInDays } from "date-fns";

function getSecondsFromExpiry(expiry: number, shouldRound = false) {
    const now = new Date().getTime();
    const milliSecondsDistance = expiry - now;
    if (milliSecondsDistance > 0) {
        const val = milliSecondsDistance / 1000;
        return shouldRound ? Math.round(val) : val;
    }
    return 0;
}

function getTimeFromSeconds(secs: number) {
    const totalSeconds = Math.ceil(secs);
    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
    };
}

const DEFAULT_DELAY = 1000;

type TimeDifferences = {
    date: Date;
};

const useTimeDifferences = ({ date }: TimeDifferences) => {
    const targetDate = date.getTime();
    const [seconds, setSeconds] = useState(getSecondsFromExpiry(targetDate));
    const [completed, setCompleted] = useState(false);
    const [isRunning, setIsRunning] = useState(true);

    const now = new Date();
    const endDate = new Date(now.getTime() + targetDate);

    // This will give a more accurate results as it takes into account the varying number of days in different months and leap years.
    const years = differenceInYears(endDate, now);
    now.setFullYear(now.getFullYear() + years);

    const months = differenceInMonths(endDate, now);
    now.setMonth(now.getMonth() + months);

    const remainingDays = differenceInDays(endDate, now);

    useInterval(
        () => {
            const secondsValue = getSecondsFromExpiry(targetDate);
            setSeconds(secondsValue);
            if (secondsValue <= 0) {
                setIsRunning(false);
                setCompleted(true);
            }
        },
        isRunning ? DEFAULT_DELAY : null
    );

    return {
        ...getTimeFromSeconds(seconds),
        completed,
        remainingDays,
        years,
        months,
    };
};

export { useTimeDifferences };
