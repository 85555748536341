import { PSelectWrapper } from "@porsche-design-system/components-react/ssr";
import type { PSelectWrapperProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type SelectWrapperProps = PSelectWrapperProps & BoxProps;

const SelectWrapper = chakra<typeof PSelectWrapper, SelectWrapperProps>(PSelectWrapper);

export { SelectWrapper };
export type { SelectWrapperProps };
