import { PBanner } from "@porsche-design-system/components-react/ssr";
import type { PBannerProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type BannerProps = PBannerProps & BoxProps;

const Banner = chakra<typeof PBanner, PBannerProps>(PBanner);

export { Banner };
export type { BannerProps };
