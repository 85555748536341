import { useEffect, useMemo, useState } from "react";
import { AnimatedDigitCard } from "./AnimatedDigitCard";
import { Box } from "../../chakra-ui/box";
import { Center } from "../../chakra-ui/center";
import { Text } from "../../chakra-ui/text";
import { Flex } from "../../chakra-ui/flex";

type DigitContainerProps = {
    number: number;
    variant: "years" | "months" | "days" | "hours" | "mins" | "secs";
    title: string;
};

const getDigits = (Digit: number, variant: string) => {
    let current;

    if (Digit < 10) {
        current = [0, Digit];
    } else if ((variant === "secs" || variant === "mins") && Digit === 60) {
        current = [0, 0];
    } else {
        current = [...String(Digit)].map(Number);
    }

    return current;
};

const DigitContainer = ({ number, title, variant }: DigitContainerProps) => {
    const [hasNumberChanged, setHasNumberChanged] = useState(false);

    useEffect(() => {
        setHasNumberChanged(true);
    }, [number]);

    const { current, previous } = useMemo(() => {
        if (!hasNumberChanged) {
            const digits = getDigits(number, variant);
            return { current: digits, previous: digits };
        }

        const currentDigit = number;
        const previousDigit = number + 1;

        return {
            current: getDigits(currentDigit, variant),
            previous: getDigits(previousDigit, variant),
        };
    }, [number, variant, hasNumberChanged]);

    return (
        <Box position="relative">
            <Flex
                position="relative"
                height={{
                    base: 9,
                    md: 14,
                }}
            >
                <AnimatedDigitCard currentDigits={current} previousDigits={previous} />
            </Flex>
            <Center
                textAlign="center"
                position="relative"
                mt={{
                    base: 0.5,
                    md: 1.5,
                }}
            >
                <Text
                    suppressHydrationWarning
                    size="caption"
                    fontWeight="600"
                    textTransform="uppercase"
                    color="allWhite"
                >
                    {title}
                </Text>
            </Center>
        </Box>
    );
};

export { DigitContainer };
