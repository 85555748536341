import { PButtonGroup } from "@porsche-design-system/components-react/ssr";
import type { PButtonGroupProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type ButtonGroupProps = PButtonGroupProps & BoxProps;

const ButtonGroup = chakra<typeof PButtonGroup, PButtonGroupProps>(PButtonGroup);

export { ButtonGroup };
export type { ButtonGroupProps };
