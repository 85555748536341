import { PSwitch } from "@porsche-design-system/components-react/ssr";
import type {
    PSwitchProps,
    SwitchUpdateEventDetail,
} from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type SwitchProps = PSwitchProps & BoxProps;

const Switch = chakra<typeof PSwitch, SwitchProps>(PSwitch);

export { Switch };
export type { SwitchProps, SwitchUpdateEventDetail };
