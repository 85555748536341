import { PModelSignature } from "@porsche-design-system/components-react/ssr";
import type { PModelSignatureProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type ModelSignatureProps = PModelSignatureProps & BoxProps;

const ModelSignature = chakra<typeof PModelSignature, ModelSignatureProps>(PModelSignature);

export { ModelSignature };
export type { ModelSignatureProps };
