import { Text } from "../../chakra-ui/text";
import type { TextProps } from "../../chakra-ui/text";
import { useDateDistance } from "../../../hooks/project/date-time/useDateDistance";

type DateDistanceProps = TextProps & {
    dateFormatterOptions?: Intl.RelativeTimeFormatOptions;
    locale?: string;
    children: string;
};

const DateDistance = ({ children, dateFormatterOptions, locale, ...rest }: DateDistanceProps) => {
    const timeAgo = useDateDistance(children, dateFormatterOptions, locale);

    return (
        <Text {...rest} as="time" dateTime={children} suppressHydrationWarning>
            {timeAgo}
        </Text>
    );
};

export { DateDistance };
export type { DateDistanceProps };
