import { useMemo } from "react";

/**
 * Thank you, ChatGPT, and internet <3
 */

interface TimeDifferences {
    years: number;
    months: number;
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    past: boolean;
}

const getTimeDifferences = (now: Date, targetDate: Date): TimeDifferences => {
    const past = now.getTime() > targetDate.getTime();
    const diff = past ? now.getTime() - targetDate.getTime() : targetDate.getTime() - now.getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    let months = Math.floor(days / 30.436875);
    const years = Math.floor(days / 365.25);

    if (months >= 12) {
        months = months % 12;
    }

    return { years, months, weeks, days, hours, minutes, seconds, past };
};

const useDateDistance = (
    date: Date | string,
    options?: Intl.RelativeTimeFormatOptions,
    locale = "default"
): string => {
    const now = useMemo(() => new Date(), []);
    const targetDate = useMemo(() => (typeof date === "string" ? new Date(date) : date), [date]);

    const opts: Intl.RelativeTimeFormatOptions = useMemo(
        () => ({
            numeric: "auto",
            ...options,
        }),
        [options]
    );

    const timeDifferences = useMemo(() => getTimeDifferences(now, targetDate), [now, targetDate]);

    const rtf = useMemo(() => new Intl.RelativeTimeFormat(locale, opts), [locale, opts]);

    return useMemo(() => {
        // Define time units without 'past' boolean
        const units: (keyof Omit<TimeDifferences, "past">)[] = [
            "years",
            "months",
            "weeks",
            "days",
            "hours",
            "minutes",
            "seconds",
        ];

        for (const unit of units) {
            const value = timeDifferences[unit];
            if (value > 0) {
                const intlUnit = unit.endsWith("s")
                    ? (unit.slice(0, -1) as Intl.RelativeTimeFormatUnit)
                    : unit;
                return rtf.format(timeDifferences.past ? -value : value, intlUnit);
            }
        }
        return rtf.format(0, "second");
    }, [timeDifferences, rtf]);
};

export { useDateDistance };
