import { DigitBox } from "./DigitBox";
import { Box } from "../../chakra-ui/box";
import type { BoxProps } from "../../chakra-ui/box";

const DotProps: BoxProps = {
    as: "span",
    height: 1,
    width: 1,
    bgColor: "allWhite",
};

const NumberDivider = () => {
    return (
        <DigitBox flexDirection="column" gap={2.5} mx={{ base: "2px", md: 2 }}>
            <Box {...DotProps} />
            <Box {...DotProps} />
        </DigitBox>
    );
};

export { NumberDivider };
