import { PWordmark } from "@porsche-design-system/components-react/ssr";
import type { PWordmarkProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type WordmarkProps = PWordmarkProps & BoxProps;

const Wordmark = chakra<typeof PWordmark, WordmarkProps>(PWordmark);

export { Wordmark };
export type { WordmarkProps };
