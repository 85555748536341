import { PCrest } from "@porsche-design-system/components-react/ssr";
import type { PCrestProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type CrestProps = PCrestProps & BoxProps;

const Crest = chakra<typeof PCrest, CrestProps>(PCrest);

export { Crest };
export type { CrestProps };
