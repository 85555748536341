import { Global, css } from "@emotion/react";

export const PorscheFontFace = () => (
    <Global
        styles={css`
            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 700;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-ar-bold.min.1796b393faddedc93881e54a311dba3a.woff2")
                    format("woff2");
                unicode-range: U+0600-0671, U+06A1, U+06A4, U+06BA, U+06CC, U+06D5, U+06F8, U+06F9;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 400;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-ar-regular.min.413b3753fc73ddf632d629ce2a3e2e59.woff2")
                    format("woff2");
                unicode-range: U+0600-0671, U+06A1, U+06A4, U+06BA, U+06CC, U+06D5, U+06F8, U+06F9;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 700;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-cy-bold.min.4cb90bbc3b74b176f89dde4bafe712ed.woff2")
                    format("woff2");
                unicode-range: U+0400-04FF;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 400;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-cy-regular.min.b845952167192934ec3e745ffed3eb40.woff2")
                    format("woff2");
                unicode-range: U+0400-04FF;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 600;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-cy-semi-bold.min.378f7d5fcfaf79d99856028cba473f5b.woff2")
                    format("woff2");
                unicode-range: U+0400-04FF;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 700;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-gr-bold.min.d2546b1baee38256478dc4bcf3906820.woff2")
                    format("woff2");
                unicode-range: U+0370-03FF;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 400;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-gr-regular.min.0b3d9b30540bfb31570b69a13de10cea.woff2")
                    format("woff2");
                unicode-range: U+0370-03FF;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 600;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-gr-semi-bold.min.f3a9ae0329f3b2668b2e39767b15399b.woff2")
                    format("woff2");
                unicode-range: U+0370-03FF;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 700;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-la-bold.min.0fbdc6d73f04137fff6e4169e8c30e2c.woff2")
                    format("woff2");
                unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF,
                    U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F,
                    U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF,
                    U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 400;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-la-regular.min.b8f1c203bd2ac5334247025236ba8d86.woff2")
                    format("woff2");
                unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF,
                    U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F,
                    U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF,
                    U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 600;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-la-semi-bold.min.b5f6fca7225aa73578f65320bcf79b45.woff2")
                    format("woff2");
                unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF,
                    U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F,
                    U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF,
                    U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 700;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-pa-bold.min.f30f194bce5688a42a041b15b330c706.woff2")
                    format("woff2");
                unicode-range: U+067C, U+067E, U+0681, U+0682, U+0685, U+0686, U+0689, U+067C,
                    U+0693, U+0696, U+0698, U+069A, U+06A9, U+06AB, U+06AF, U+06BC, U+06CD, U+06D0;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 400;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-pa-regular.min.3989814a10cdd1565bc69cbfaa72a943.woff2")
                    format("woff2");
                unicode-range: U+067C, U+067E, U+0681, U+0682, U+0685, U+0686, U+0689, U+067C,
                    U+0693, U+0696, U+0698, U+069A, U+06A9, U+06AB, U+06AF, U+06BC, U+06CD, U+06D0;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 700;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-ur-bold.min.49a86a4b90a6e462074c8a9e594c3b72.woff2")
                    format("woff2");
                unicode-range: U+0679, U+0688, U+0691, U+06BE, U+06C0-06C3, U+06D2-06D5, U+06F0-06F9;
                font-display: swap;
            }

            @font-face {
                font-family: Porsche Next;
                font-style: normal;
                font-weight: 400;
                src: url("https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-ur-regular.min.ad23819fb915a288ecfd39bdd5c7fda9.woff2")
                    format("woff2");
                unicode-range: U+0679, U+0688, U+0691, U+06BE, U+06C0-06C3, U+06D2-06D5, U+06F0-06F9;
                font-display: swap;
            }
        `}
    />
);
