import { PFlyout } from "@porsche-design-system/components-react/ssr";
import type { PFlyoutProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type FlyoutProps = PFlyoutProps & BoxProps;

const Flyout = chakra<typeof PFlyout, FlyoutProps>(PFlyout);

export { Flyout };
export type { FlyoutProps };
