import { Text } from "../../chakra-ui/text";
import { Heading } from "../../chakra-ui/heading";
import type { TextProps } from "../../chakra-ui/text";
import { useDateFormatter } from "../../../hooks/project/date-time/useDateFormatter";

type DateTimeProps = TextProps & {
    dateFormatterOptions?: Intl.DateTimeFormatOptions;
    locale?: string;
    isHeading?: boolean;
    children: string;
    showInLocalTime?: boolean;
};

const DateTime = ({
    children,
    dateFormatterOptions,
    locale,
    isHeading = false,
    showInLocalTime = false,
    ...rest
}: DateTimeProps) => {
    const date = useDateFormatter(children, dateFormatterOptions, locale, showInLocalTime);

    if (isHeading) {
        return (
            <Heading {...rest} as="time" dateTime={children} suppressHydrationWarning>
                {date}
            </Heading>
        );
    } else {
        return (
            <Text {...rest} as="time" dateTime={children} suppressHydrationWarning>
                {date}
            </Text>
        );
    }
};

export { DateTime };
export type { DateTimeProps };
