import {
    PStepperHorizontal,
    PStepperHorizontalItem,
} from "@porsche-design-system/components-react/ssr";
import type {
    PStepperHorizontalProps,
    PStepperHorizontalItemProps,
    StepperHorizontalItemState,
    StepperHorizontalUpdateEventDetail,
} from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type StepperHorizontalProps = PStepperHorizontalProps & BoxProps;
type StepperHorizontalItemProps = PStepperHorizontalItemProps & BoxProps;

const StepperHorizontal = chakra<typeof PStepperHorizontal, StepperHorizontalProps>(
    PStepperHorizontal
);

const StepperHorizontalItem = chakra<typeof PStepperHorizontalItem, StepperHorizontalItemProps>(
    PStepperHorizontalItem
);

export { StepperHorizontal, StepperHorizontalItem };
export type {
    StepperHorizontalProps,
    StepperHorizontalItemProps,
    StepperHorizontalUpdateEventDetail,
    StepperHorizontalItemState,
};
