import type { TextProps } from "../../components/chakra-ui/text";
import { Text } from "../../components/chakra-ui/text";
import { useCldVideoPlayer } from "./cld-video-context";
import { useSecondsAsTime } from "./hooks";

const CldVideoTimeElapsed = (props: Omit<TextProps, "children">) => {
    const {
        state: { played },
    } = useCldVideoPlayer();

    const elapsedTime = useSecondsAsTime(played.playedSeconds);

    return (
        <Text as="span" {...props}>
            {elapsedTime}
        </Text>
    );
};
CldVideoTimeElapsed.displayName = "CldVideoTimeElapsed";

export { CldVideoTimeElapsed };
