import { PDisplay } from "@porsche-design-system/components-react/ssr";
import type { PDisplayProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type DisplayProps = PDisplayProps & BoxProps;

const Display = chakra<typeof PDisplay, DisplayProps>(PDisplay);

export { Display };
export type { DisplayProps };
