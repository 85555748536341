import { useCallback } from "react";
import { useCldVideoPlayer } from "./cld-video-context";
import { Button } from "../../components/porsche-design-system/button";
import type { ButtonProps } from "../../components/porsche-design-system/button";
import { ButtonPure } from "../../components/porsche-design-system/button-pure";

type CldVideoTogglePiPProps = Omit<ButtonProps, "aria-label"> & {
    mobile?: boolean;
};

const CldVideoTogglePiP = (props: CldVideoTogglePiPProps) => {
    const { mobile, ...rest } = props;

    const {
        state: { playerRef, isShowingPiP },
        dispatch,
    } = useCldVideoPlayer();

    const handleClick = useCallback(() => {
        if (!playerRef?.current) {
            return;
        }

        if (isShowingPiP) {
            dispatch({ type: "HIDE_PICTURE_IN_PICTURE" });
            dispatch({ type: "PLAY" });
        } else {
            dispatch({ type: "SHOW_PICTURE_IN_PICTURE" });
        }
    }, [isShowingPiP, playerRef, dispatch]);

    if (mobile) {
        return (
            <ButtonPure
                onClick={handleClick}
                theme="dark"
                {...rest}
                hideLabel={true}
                aria-label={isShowingPiP ? "exit picture-in-picture" : "enter picture-in-picture"}
                icon="image"
                border="2px solid var(--colors-allWhite)"
                borderRadius="var(--radii-small)"
                size="x-large"
            />
        );
    }

    return (
        <Button
            onClick={handleClick}
            theme="dark"
            variant="secondary"
            {...rest}
            hideLabel={true}
            aria-label={isShowingPiP ? "exit picture-in-picture" : "enter picture-in-picture"}
            icon="image"
        />
    );
};
CldVideoTogglePiP.displayName = "CldVideoTogglePiP";

export { CldVideoTogglePiP };
