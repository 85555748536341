const getAspectRatio = (width: number, height: number) => {
    if (width === height) {
        return "1:1";
    } else if (width > height) {
        return "16:9";
    } else {
        return "4:3";
    }
};

export { getAspectRatio };
