import { DateFormatter } from "@internationalized/date";
import { useRouter } from "next/router";
import { useMemo } from "react";

const COMMON_DATE_TIME_FORMATTER_OPTIONS = {
    Date: {
        year: "numeric",
        month: "long",
        day: "numeric",
    },
    DateTime: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    },
    Time: {
        hour: "numeric",
        minute: "numeric",
    },
} as const;

const getOriginalTimezoneOffset = (dateString: string) => {
    const match = /([+-]\d{2}:\d{2}|Z)$/.exec(dateString);
    return match ? match[0] : "UTC";
};

const useDateFormatter = (
    date: Date | string,
    options?: Intl.DateTimeFormatOptions,
    locale?: string,
    localTime: boolean = false
): string => {
    const { locale: nextLocale } = useRouter();

    const { dateObj, timezoneOffset } = useMemo(() => {
        if (typeof date === "string") {
            return {
                dateObj: new Date(date),
                timezoneOffset: getOriginalTimezoneOffset(date),
            };
        }
        return { dateObj: date, timezoneOffset: "UTC" };
    }, [date]);

    const opts: Intl.DateTimeFormatOptions = useMemo(
        () => ({
            timeZone: localTime ? Intl.DateTimeFormat().resolvedOptions().timeZone : "UTC",
            ...options,
        }),
        [localTime, options]
    );

    const formattedDate = useMemo(
        () => new DateFormatter(locale ?? nextLocale ?? "en", opts).format(dateObj),
        [dateObj, locale, nextLocale, opts]
    );

    if (localTime) {
        return formattedDate;
    }

    if (timezoneOffset === "Z") {
        return formattedDate;
    }

    return `${formattedDate} UTC${timezoneOffset}`;
};

export { useDateFormatter, COMMON_DATE_TIME_FORMATTER_OPTIONS };
