import { PSpinner } from "@porsche-design-system/components-react/ssr";
import type { PSpinnerProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type SpinnerProps = PSpinnerProps & BoxProps;

const Spinner = chakra<typeof PSpinner, SpinnerProps>(PSpinner);

export { Spinner };
export type { SpinnerProps };
