import { Heading } from "../../chakra-ui/heading";
import { motion } from "framer-motion";
import type { ComponentProps } from "react";

const AnimatedHeading = motion(Heading);

type MotionTextProps = ComponentProps<typeof AnimatedHeading>;

const Digit = ({ children, ...props }: MotionTextProps) => {
    return (
        <AnimatedHeading
            suppressHydrationWarning
            fontWeight="normal"
            size="headingXXLarge"
            color="allWhite"
            pointerEvents="none"
            as="span"
            {...props}
        >
            {children}
        </AnimatedHeading>
    );
};

export { Digit };
