import { PFieldset } from "@porsche-design-system/components-react/ssr";
import type { PFieldsetProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type FieldsetProps = PFieldsetProps & BoxProps;

const Fieldset = chakra<typeof PFieldset, FieldsetProps>(PFieldset);

export { Fieldset };
export type { FieldsetProps };
