import { PCheckboxWrapper } from "@porsche-design-system/components-react/ssr";
import type { PCheckboxWrapperProps } from "@porsche-design-system/components-react/ssr";
import { chakra } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

type CheckboxWrapperProps = PCheckboxWrapperProps & BoxProps;

const CheckboxWrapper = chakra<typeof PCheckboxWrapper, CheckboxWrapperProps>(PCheckboxWrapper);

export { CheckboxWrapper };
export type { CheckboxWrapperProps };
